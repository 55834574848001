import React, { useState, useEffect } from 'react'
import nfthidden from "../assets/img-sspunk/nft1.gif"
import { gql } from '@apollo/client';
import { apiURI } from "../config";
import { useAddress } from "@thirdweb-dev/react";
import { Web3Button, useSDK } from "@thirdweb-dev/react";
import { useNavigate } from 'react-router-dom'

// const web3 = new Web3('');

const MintDialog = ({ mint, setMint, minInfo, getNFT }) => {

    const [Remaining, setRemaining] = useState(0);
    const [Loading, setLoading] = useState(true);
    const [maxQuantity, setMaxQuantity] = useState(0)
    const [minCount, setminCount] = useState(1)

    const [viewNft, setviewNft] = useState("mint")
    const address = useAddress();
    const sdk = useSDK();
    const navigate = useNavigate()

    const handleMintFunction = async (contract) => {

        console.log("Minting NFT");

        // const txn = await sdk.wallet.sendRawTransaction({
        //     to:"0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b",
        //     value:"100",
        //     gasLimit: 21000,
        //     gasPrice: 100000,
        //     data:
        // })
        const amount = (minCount * 1).toString();
        const data = await sdk.wallet.transfer("0x51CF8411AA142F55600Ce5a8CD1D07bBC3De3cDb", amount);
        // console.log("data", data.receipt.transactionHash)

        // checkTransaction(data.receipt.transactionHash)
        setLoading(true)



        return new Promise((resolve) => {
            let walletAddress = address.toLowerCase();
            mintData(walletAddress, data.receipt.transactionHash);
            const interval = setInterval(() => {
                if (!Loading) {
                    clearInterval(interval);
                    resolve();
                }
            }, 2000);
        });

    }
    const viewFunc = () => {
        setMint(false)
        navigate("/collections")

    }

    const mintData = (walletAddress, txnHash) => {

        setviewNft("minting")
        if (address !== null) {
            try {
                var query = `
                        mutation Mutation($input: MintInput) {
                            mintNFT(input: $input)
                          }
                                    `;
                fetch(apiURI.URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "x-domain-agent": "superstarpunks.com"
                    },
                    body: JSON.stringify({
                        query,
                        variables: {
                            "input": {
                                "walletAddress": walletAddress,
                                "count": minCount,
                                "paymentHash": txnHash
                            }
                        },
                    }),
                })
                    .then((response) => {
                        const json = response.json();

                        return json;
                    })
                    .then(async (data) => {

                        console.log(data);
                        setviewNft("minted")
                        getNFT(walletAddress)
                        // setMint(false)

                        // navigate("/collections")
                    });
            } catch (error) {
                console.log(error)
            }

        }
        else {
            alert("connect Wallet")
        }

    }


    return (
        <div className="mint_modal">
            <div className={mint ? "modal fade show" : "modal fade "} style={{ display: "block", zIndex: mint ? "1000" : "0", backgroundColor: "#393b3ca1" }}>
                <div className="modal-dialog modal-dialog-centered" >
                    <div className="modal-content" >

                        <div className="modal_overlay2">
                            <div className="modal_header">

                                <h2>Mint YOUR NFT </h2>
                                <button onClick={() => setMint(false)}>
                                    <span className="clossbtn_bg"><i className="fa-solid fa-xmark"></i></span>
                                </button>
                            </div>
                            {viewNft === "mint" ?
                                <div className="modal_body text-center" style={{ display: "block" }}>

                                    <div>
                                        <div className="mint_img">
                                            <img src={nfthidden} alt="img" style={{ maxWidth: "50%", borderRadius: "25px" }} />
                                        </div>
                                        <div className="mint_count_list">
                                            <ul>
                                                <li>
                                                    <h5>Remaining</h5>
                                                    <h5>{minInfo?.remaining}/<span>500</span></h5>
                                                </li>
                                                <li>
                                                    <h5>Price</h5>
                                                    <h5>1 BNB</h5>
                                                </li>
                                                <li>
                                                    <h5>Quantity</h5>
                                                    <div className="mint_quantity_sect">
                                                        <button onClick={minCount > 1 ? () => { setminCount(minCount - 1) } : () => { setminCount(minCount) }}>-</button>
                                                        <p>{minCount}</p>
                                                        <button onClick={minCount < 5 ? () => { setminCount(minCount + 1) } : () => { setminCount(minCount) }}>+</button>
                                                        {/* <button onclick=>+</button> */}
                                                    </div>
                                                    <h5><span>{(minCount * 1).toFixed(2)}</span> BNB</h5>
                                                </li>

                                            </ul>

                                        </div>

                                        {/* <button className="modal_mint_btn hov_shape_show">
                                    MINT NOW
                                </button> */}
                                <div style={{width:"100%",textAlign:"center",marginBottom:"14px"}}>
                                <span style={{fontSize:"12px",fontWeight:"700",color:"white"}}>Receive 1250 OZO coins for 1 NFT Mint</span>
                                </div>
                                <button  className='modal_mint_btn hov_shape_show'  style={{ width: "100%", color: "white" }}>Sold Out</button>
                                {/* {minInfo?.remaining >0 && minInfo?.remaining!==undefined ? 
                                        // <Web3Button
                                        //     contractAddress="0x55d398326f99059fF775485246999027B3197955"
                                        //     className='modal_mint_btn hov_shape_show'
                                        //     style={{ width: "100%", color: "white" }}
                                        //     action={async (contract) => {
                                        //         try {
                                        //             await handleMintFunction(contract);
                                        //             // alert("NFT Minted successfully!");
                                        //         } catch (error) {
                                        //             console.log("Error", error.code)
                                        //             if (error.code == -32603) { alert("Insufficient Balance") }
                                        //             else {
                                        //                 alert("Something Went Wrong ! Please try again")
                                        //             }
                                        //         }
                                        //     }}
                                        // >
                                        //     MINT NOW
                                        // </Web3Button>:""
                                */}
                                 
                                        <div style={{width:"100%",textAlign:"center",marginBottom:"14px"}}>
                                <span style={{fontSize:"12px",fontWeight:"700",color:"white"}}>Note: Maximum 5 NFT Mints per Transaction</span>
                                </div>
                                    </div>
                                </div>
                                : viewNft === "minted" ?
                                    <>
                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                            <div className="mint_img mb-4">
                                                <img src={nfthidden} alt="img" style={{ maxWidth: "50%", borderRadius: "25px" }} />
                                            </div>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                <button className="connect_btn hov_shape_show cus-btn" style={{ borderRadius: "0px", padding: "10px" }} onClick={() => { viewFunc() }}>View your NFT</button>
                                            </div>

                                        </div>
                                    </> : viewNft === "minting" ?
                                        <div className="loader_first_inner" style={{ flexDirection: "column" }} >

                                            <div className="circular-spinner"></div>
                                            <img src={nfthidden} alt="img" style={{ maxWidth: "50%", borderRadius: "25px" }} />
                                            <h3 className='mt-5'>Minting...</h3>
                                        </div> : ""
                            }
                            <div className="modal_bottom_shape">
                                <span className="modal_bottom_shape_left"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                                <span className="modal_bottom_shape_right"><img src="assets/images/icon/hov_shape_L.svg" alt="" /></span>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default MintDialog