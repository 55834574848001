import { React, useEffect, useState } from 'react'
import starpunk from "./assets/img-sspunk/logo-sspunk.png"
import walleticon from "./assets/images/icon/connect_wallet.svg"
import { Link } from 'react-router-dom';

import ConnectWalletButton from './page/connectWalletButton';

const Navbar = ({ setMint, mint, page, mintNow }) => {

    // const [walletConnected, setWalletConnected] = useState(false)
    // const [connectWallet, setConnectWallet] = useState(true)
    // const disconnect = useDisconnect();

    const [isSticky, setIsSticky] = useState(false);
    const [mobileMenu, setmobileMenu] = useState(false)


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsSticky(scrollPosition > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    // const handleDisconnect =  () => {
    //     console.log("Disconnecting...");
    //     // setConnectWallet(false)
    //     // setWalletConnected(fals);
    //     disconnect();
    //     console.log("Disconnected");
    // }


    return (
        <div className={`bithu_header ${isSticky ? 'sticky' : ''}`} id="navbar">
            <div className="container">
                <div className="bithu_menu_sect">
                    <div className="bithu_menu_left_sect">
                        <div className="logo">
                            <a href="https://superstarpunks.com">
                                <img className="logo-sspunk" src={starpunk} alt="logo" /></a>
                        </div>
                    </div>
                    <div className="bithu_menu_right_sect bithu_v2_menu_right_sect">
                        <div className="bithu_menu_list bithuv4_menu_list" style={{ maxWidth: "680px" }}>
                            <ul>
                                {page == "home" ? <>
{/* 
                                    <li className='px-2'><a href="https://superstarpunks.com">Home</a></li> */}
                                    <li className='px-2'><a href="#about">About</a></li>
                                    <li className='px-2'><a href="#utilities">Utilities</a></li>
                                    <li className='px-2'><a href="#features">Features</a></li>
                                    <li className='px-2'><a href="#traits">Traits</a></li>

                                    {/* <li className='px-2'><a href="#utilities">View NFT</a></li> */}
                                    <li className='px-2' >
                                        <a href="https://superstarpunks.com/collections" target="_blank" rel="noopener noreferrer" style={{ width: "90px", display: "block" }}>View NFT </a>
                                    </li>
                                    <li className='px-2'>
                                        <ConnectWalletButton mintNow={mintNow} mobileMenu={mobileMenu} />
                                    </li>
                                </>

                                    : page == "collections" ?
                                        <>

                                            {/* <li><a href="https://superstarpunks.com">Home</a></li> */}
                                            <li><a href="https://superstarpunks.com" onClick={() => { localStorage.setItem("page", "about") }}>About</a></li>
                                            <li><a href="https://superstarpunks.com" onClick={() => { localStorage.setItem("page", "utilities") }}>Utilities</a></li>
                                            <li><a href="https://superstarpunks.com" onClick={() => { localStorage.setItem("page", "features") }}>Features</a></li>
                                            <li><a href="https://superstarpunks.com" onClick={() => { localStorage.setItem("page", "traits") }}>Traits</a></li>
                                            <li className='px-2' > <Link to="/collections"> <a href="#" style={{ width: "90px", display: "block" }}>View NFT </a></Link></li>

                                            <li className='px-2'>
                                                <ConnectWalletButton mintNow={mintNow} mobileMenu={mobileMenu} />
                                            </li>

                                        </>
                                        : ""
                                }

                                {/* <li><a href="#faq">FAQ</a></li> */}


                            </ul>
                        </div>
                        <div className="bithu_menu_right_buttons bithuv4_menu_right_buttons">
                            <button className="menu_bar" onClick={() => setmobileMenu(true)}>
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div className="connect-btn-wrapper">
                                {/* <button className="connect_btn hov_shape_show cus-btn" data-bs-toggle="modal" data-bs-target="#connectModal" onClick={() => setMint(true)}>
                                    
                                    Mint Now
                                    <span className="hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    <span className="hov_shape2"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    <span className="square_hov_shape"></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bithu_mobile_menu" id="bithu_mobile_menu" style={{ display: mobileMenu ? "block" : "none" }}>
                    <div className="bithu_mobile_menu_content">
                        <div className="mobile_menu_logo">

                            <span><img class="m-m-logo" src={starpunk} alt=""></img></span>
                            <button className="mobile_menu_close_btn" onClick={() => setmobileMenu(false)}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="bithu_mobile_menu_list">
                            <ul>
                                <li><a href="https://superstarpunks.com">Home</a></li>
                                <li class="mobile-menu-hide"><a href="#about">About</a></li>
                                <li class="mobile-menu-hide"><a href="#utilities">Utilities</a></li>
                                <li class="mobile-menu-hide"><a href="#features">Features</a></li>
                                <li class="mobile-menu-hide"><a href="#traits">Traits</a></li>

                                <li className='px-0' >
                                    <Link to="/collections"><a href="#" style={{ width: "90px", display: "block" }}>View NFT </a></Link></li>
                                <li className='px-0 mt-4'>
                                    <ConnectWalletButton mintNow={mintNow} mobileMenu={mobileMenu} />
                                </li>
                                {/* 
                          
                                <li class="mobile-menu-hide"><a href="#faq">FAQ</a></li> */}
                            </ul>
                        </div>
                        <div className="mobile_menu_btns">
                            <ul className="mobile_menu_social_links">
                                <li><a href="https://t.me/superstarpunks" target="_blank"><i class="fa-brands fa-telegram"></i></a></li>
                                <li><a href="https://twitter.com/SuperStarPunks" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                                <li><a href="https://medium.com/@SuperStarPunks" target="_blank"><i class="fa-brands fa-medium" ></i></a></li>
                            </ul>
                            <div className="connect-btn-wrapper w-100">
                                {/* <button className="connect_btn hov_shape_show mobile-menu-hide" onClick={() => { setmobileMenu(false); setMint(true) }}> */}
                                {/* <img src={walleticon} alt="" /> */}
                                {/* Mint Now
                                    <span className="hov_shape1"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    <span className="hov_shape2"><img src="assets/images/icon/hov_shape_s.svg" alt="" /></span>
                                    <span className="square_hov_shape"></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Navbar