import React from 'react'
import { ConnectWallet, useDisconnect } from "@thirdweb-dev/react";
import { useAddress } from "@thirdweb-dev/react";
import { Link } from 'react-router-dom';

const ConnectWalletButton = ({mintNow,mobileMenu}) => {

    const disconnect = useDisconnect();

    const address = useAddress();
    

    if (address === undefined) {
        return (
            <ConnectWallet
             style={{fontSize:mobileMenu?"16px":"16px",fontFamily:'Bakbak One'}}
                className='connect_btn hov_shape_show cus-btn'
                detailsBtn={() => (<button className="connect_btn hov_shape_show cus-btn" ></button>)}
            />)
    }

    if (address !== undefined) {
        return (
            <div className='d-flex'>
              
                <button className="connect_btn hov_shape_show cus-btn" style={{fontSize:mobileMenu?"16px":"16px",padding:mobileMenu?"10px":"0px",fontFamily:'Bakbak One'}} onClick={mintNow}>Mint NFT</button>
                <button className="connect_btn hov_shape_show cus-btn" style={{fontSize:mobileMenu?"16px":"16px",padding:mobileMenu?"10px":"0px",marginLeft:mobileMenu?"5px":"10px",fontFamily:'Bakbak One'}} onClick={disconnect}>Disconnect</button>
            </div>
        )
    }

}

export default ConnectWalletButton