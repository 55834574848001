import React from 'react'
import logo from "./assets/img-sspunk/logo-sspunk.png"


const Footer = () => {
  return (
<div class="bithu_v1_main_footer bithu_v4_main_footer">
                <div class="bithu_v1_main_footer_overlay">
                </div>
                <div class="footer_bottom v4_footer_bottom">
                    <div class="footer_bottom_content">
                        {/* <!-- <span class="footer_shapes_left"><img src="assets/images/icon/footer_shapes_left.png" alt="" /></span>
                        <span class="footer_shapes_right"><img src="assets/images/icon/footer_shapes_right.png" alt="" /></span> --> */}
                        <div class="container">
                            <div class="col-lg-12">
                               
                                <div class="text-center">

                                    <div class="mt-10">
                                        <a href="#"><img class="logo-sspunk footer" src={logo} alt=""/></a>
                                    </div>

                                    <div class="copiright_text pt-20 pb-20">
                                        <p>Copyright © 2023 SuperStarPunks.</p>
                                    </div>

                                </div> 
                            
                            

                                {/* <!-- <a href="#" class="bact_to_top_btn"><img src="assets/images/icon/back_to_top.svg" alt="" /></a> -->
                                <!-- <div class="bottom_footer_right">
                                    <ul>
                                        <li><a href="#home">Home</a></li>
                                        <li><a href="#about">About</a></li>
                                        <li><a href="#roadmap">Roadmap</a></li>
                                        <li><a href="#team">Team</a></li>
                                        <li><a href="#faq">FAQ</a></li>
                                    </ul>
                                </div> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default Footer