
import './App.css';
import Page from './page/page';

import { Routes, Route} from 'react-router-dom';
import "./assets/css/bootstrap.min.css"
import "./assets/css/style.css"
import "./assets/css/custom.css"
import "./assets/css/responsive.css"
import "./assets/css/fontawesome-min.css"
import "./assets/css/off-canvas.css"
import "./assets/css/ico-moon-fonts.css"
import "./assets/css/sc-spacing.css"
import "./assets/css/animate.css"
import "./assets/css/swiper.css"
import Collections from './page/collection';
import { useState } from 'react';



function App() {

  const [page, setpage] = useState("home")
  return (
    <div className="App">
          <Routes>
          <Route path="/" element={<Page setpage={setpage} page={page}/>} />
          <Route path="/home" element={<Page setpage={setpage} page={page}/>} />
          <Route path="/collections" element={<Collections setpage={setpage} page={page}/>} />
          </Routes>
    
    </div>
  );
}

export default App;
