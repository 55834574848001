import { React, useEffect, useState } from 'react'
import Footer from '../footer'
import Navbar from '../navbar'
import steps from "../assets/img-sspunk/home-shape.png"
import nft1 from "../assets/img-sspunk/nft-sl-1.jpg"
import nft2 from "../assets/img-sspunk/nft-sl-2.jpg"
import nft3 from "../assets/img-sspunk/nft-sl-3.jpg"
import nft4 from "../assets/img-sspunk/nft-sl-4.jpg"
import { apiURI } from "../config";
import { useAddress } from "@thirdweb-dev/react";
import MintDialog from './MintDialog';


const Collections = ({ page, setpage, }) => {

    const [connect, setconnect] = useState(false)
    const [mint, setMint] = useState(false)
    const address = useAddress();
    const [walletStatus, setwalletStatus] = useState(false)
    const [minInfo, setminInfo] = useState()
    const [mintProcess, setmintProcess] = useState(false)
    const [nftDetails, setnftDetails] = useState()
    useEffect(() => {
        setpage("collections")
    }, []);


    const getNFT = async (walletAddress) => {

        console.log("inside get");
        try {
            var query = `
                query GetNFTsByWallet($walletAddress: String) {
                    getNFTsByWallet(walletAddress: $walletAddress) {
                      walletAddress
                      nft_id
                      traits {
                        trait_type
                        value
                      }
                      imageUrl
                    }
                  }
                  
                            `;
            fetch(apiURI.URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "x-domain-agent": "superstarpunks.com"
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        "walletAddress": walletAddress
                    },
                }),
            })
                .then((response) => {
                    const json = response.json();

                    return json;
                })
                .then(async (data) => {
                    setnftDetails(data)
                    console.log(data, "nft collections");
                });
        } catch (error) {

        }

    };




    const getMintInfo = async (walletAddress) => {
        if (walletAddress === undefined) {
            alert("Please connect your wallet to mint")
        }
        else {




            console.log("inside get");
            try {
                var query = `
              query ExampleQuery($walletAddress: String) {
                id:
                getMintInfo(walletAddress: $walletAddress) {
                    max_quantity
                    remaining
                }
              }
                            `;
                fetch(apiURI.URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "x-domain-agent": "superstarpunks.com"
                    },
                    body: JSON.stringify({
                        query,
                        variables: {
                            "walletAddress": walletAddress
                        },
                    }),
                })
                    .then((response) => {
                        const json = response.json();

                        return json;
                    })
                    .then(async (data) => {
                        setminInfo(data?.data?.id)
                        console.log(data);
                    });
            } catch (error) {

            }
        }
    };


    const mintNow = () => {

        if (address === undefined) {
            alert("Please connect your wallet to mint")
        }
        else {
            let walletAddress = address.toLowerCase();
            getMintInfo(walletAddress);
            setMint(true);
        }
        //check if wallet is connected using cookies or local storage and call getMintInfo.

    }


    useEffect(() => {

        if (address === undefined) {
            setwalletStatus(false)
        }
        else {
            setwalletStatus(true)
            let walletAddress = address.toLowerCase();
            getNFT(walletAddress);

        }

    }, [address]);




    return (
        <>
            <div >
                <section className="loader_first" style={{ display: "none" }}>
                    <div className="loader_first_inner">
                        <div className="circular-spinner"></div>
                        <h3 style={{ marginTop: "200px" }}>Minting..</h3>
                    </div>
                </section>

                <section className="loader_first" style={{ display: "none" }}>
                    <div className="loader_first_inner">
                        <div className="circular-spinner"></div>
                    </div>
                </section>
                <Navbar connect={connect} setMint={setMint} page={page} mintNow={mintNow}></Navbar>
                <MintDialog mint={mint} setMint={setMint} setminInfo={setminInfo} minInfo={minInfo} getNFT={getNFT} />
                <div className="bithu-breadcrumbs-section" id="home">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <div className="sub-inner mb-15">
                                        {/* <a className="breadcrumbs-link" href="#">Home</a><span className="sub-title"> Collections</span> */}
                                        <img className="heading-left-image" src={steps} alt="Steps-Image" />
                                    </div>
                                    <h2><a className="title" href="#">NFTS</a></h2>
                                </div>
                            </div>

                            {/* <div className="col-lg-7 breadcrumbs-form md-mt-40">
                        <form>
                            <input type="text" id="Search" name="search" placeholder="Search by articles , categories"></input>
                            <span className="submit"><i className="icon-search"></i><input type="submit"></input></span>
                        </form>
                    </div> */}
                        </div>
                    </div>
                </div>
                <div className="collections-body-section pt-100 pb-140">
                    <div className="container">
                        <div className="collections-body-inner">
                            <div className="row">
                                {/* <div className="col-lg-3 col-md-4">
                            <div className="collections-body-left"> */}
                                {/* <form>
                                    <div className="collections-price-filter">
                                        <select name="PriceFilter">
                                            <option value="LowHigh" selected>Price: Low to High</option>
                                            <option value="HighLow">Price: High to Low</option>
                                            <option value="recentlyListed">Recently Listed</option>
                                            <option value="Favorited">Most Favorited</option>
                                            <option value="Favorited">Oldest</option>
                                        </select>
                                    </div>
                                    <div className="collections-filter-checkbox-collaps">
                                        <div className="collapsible active">
                                            <button type="button">Background</button>
                                            <div className="content">
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Blue <span>(23)</span>
                                                        <input type="checkbox" checked="checked"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Green <span>(13)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Pink <span>(18)</span>
                                                        <input type="checkbox" checked="checked"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Red <span>(24)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Rainbow <span>(8)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Yellow <span>(288)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="collections-filter-checkbox-collaps">
                                        <div className="collapsible active">
                                            <button type="button">Clothes</button>
                                            <div className="content">
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Denim <span>(78)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Black Hoodie <span>(35)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Sushi <span>(22)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Hawaii Shirt <span>(9)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="collections-filter-checkbox-collaps">
                                        <div className="collapsible active">
                                            <button type="button">Face</button>
                                            <div className="content">
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Pretty <span>(105)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">happy Smile <span>(78)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Blushing <span>(52)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Yummy <span>(43)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="collections-filter-checkbox-collaps">
                                        <div className="collapsible active">
                                            <button type="button">Eye</button>
                                            <div className="content">
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Pretty <span>(105)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">happy Smile <span>(78)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Blushing <span>(52)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                
                                                <div className="collections-filter-checkbox">
                                                    <label className="checkboxContainer">Yummy <span>(43)</span>
                                                        <input type="checkbox"></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                                {/* </div>
                        </div> */}
                                <div className="col-lg-12 col-md-12">
                                    {walletStatus && nftDetails?.data?.getNFTsByWallet !== null ?
                                        <div className="collections-body-right">
                                            <div className="row">
                                                {nftDetails?.data?.getNFTsByWallet?.length > 0 &&
                                                    nftDetails?.data?.getNFTsByWallet.map((i, index) => (

                                                        <div className="col-lg-4 col-sm-6">
                                                            <div className="collections-product-card">
                                                                <div className="collections-product-img">
                                                                    <span><img src={i?.imageUrl} alt="img" className="img-fluid" /></span>
                                                                </div>
                                                                <div className="collections-product-info">
                                                                    <h6>SuperStarPunk #{i?.nft_id + 1}</h6>
                                                                    <p style={{ color: "#a383ff", fontWeight: "700" }}><span>Trait Type</span> <span>  Value</span></p>
                                                                    <p><span>Backgrounds :</span> <span>  {i?.traits[0]?.value}</span></p>
                                                                    <p><span>Punk Type :</span> <span>  {i?.traits[1]?.value}</span></p>
                                                                    <p><span>Glasses :</span> <span>   {i?.traits[2]?.value}</span></p>
                                                                    <p><span>Hats :</span> <span>   {i?.traits[3]?.value}</span></p>
                                                                    <p><span>Ball :</span> <span>   {i?.traits[4]?.value}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="gamfi-navigation">
                                            </div>
                                        </div>
                                        : walletStatus && nftDetails?.data?.getNFTsByWallet == null ?
                                            <div>
                                                <h2 className='mt-4'>Currently you dont have any NFT's in you wallet</h2>
                                            </div> :
                                            <div>
                                                <h2 className='mt-4'> Please connect your wallet to view NFT's</h2>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </>

    )
}

export default Collections